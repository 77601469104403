import React from 'react';
import { graphql } from 'gatsby';
import JobBody from './../components/job-body'
import Default from 'gatsby-theme-carbon/src/templates/Default';

import { getPostedLabel, getAddress, getSalary, getExperience, getCategoryLinks, getJobContent, getJobAnchors, getJobApplyMethod, getJobSeoDesc, getPositionTypeNames } from './../util';

const Job = ({ data, location, pageContext }) => {
  
  const job_data = {
    ...data.job,
    education: data.job.relationships.field_education,
    bilingual: data.job.relationships.field_bilingual,
    position: getPositionTypeNames(data.job.relationships.field_position),
    address: getAddress(data.job.field_address),
    salary: getSalary(data.job.field_salary_number, data.job.relationships.field_salary_type, data.job.field_salary_number_max),
    experience: getExperience(data.job.relationships.field_experience_level, data.job.relationships.field_experience_years),
    category_links: getCategoryLinks(data.job.relationships.field_category),
    posted: getPostedLabel(data.job.field_publish_date),
    content: getJobContent(data.job),
    seo_description: getJobSeoDesc(data.job),
    anchors: getJobAnchors(data.job),
    method: getJobApplyMethod(data.job.relationships.field_application_method, data.job.field_required_documents, data.job.field_application_url),
    is_expired: false
  };
  
	pageContext = {...pageContext, frontmatter:
		{
		  title: job_data.title,
      description: job_data.seo_description
		}
  };
  
	return (
		<Default pageContext={pageContext} location={location}>
      <JobBody job_data={job_data} isPreview={false} />
    </Default>
  )
}

export default Job;

export const query = graphql`
  query($slug: String!) {
    job: nodeJobListing(fields: { slug: { eq: $slug } }) {
        id
        title
        drupal_id
        field_benefits {
          processed
        }
        field_address {
          address_line1
          address_line2
          locality
          administrative_area
        }
        field_org_email
        field_company_name
        field_salary_number
        field_salary_number_max
        field_required_documents
        field_application_url {
          uri
        }
        relationships {
          field_salary_type {
            name
            description {
              processed
            }
          }
          field_category {
            name
          }
          field_education {
            name
          }
          field_bilingual {
            name
          }
          field_experience_level {
            name
          }
          field_experience_years {
            name
          }
          field_position {
            name
          }
          field_application_method {
            name
          }
          field_expiration {
            name
          }
        }
        field_publish_date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        field_description {
          processed
        }
        field_preferred_qualifications {
          processed
        }
        field_expired
        field_archived
        field_required_qualifications {
          processed
        }
    }
    expirations: allTaxonomyTermExpirationLengths {
      nodes {
        drupal_id
        name
      }
    }
  }
`
